<script>
	import Button from '$lib/Button.svelte';
	import SavingIcon from '$lib/icons/SavingIcon.svelte';
	import Modal from './Modal.svelte';
	import SendIcon from 'svelte-material-icons/Send.svelte';

	export let color = '';

	export let confirmLabel = 'Confirm';
	export let cancelLabel = 'Cancel';
	export let status = 'primary';

	export let onClose;
	export let onConfirm;
	export let onCancel;
</script>

<Modal {...$$restProps} {onClose} {status}>
	<div style="padding: 25px;">
		<slot />
	</div>

	<svelte:fragment slot="footer">
		<div />
		<div class="flex gap-15">
			{#if cancelLabel && onClose}
				<Button onClick={onCancel || onClose}>
					{cancelLabel}
				</Button>
			{/if}
			<Button color={status} onClick={onConfirm || onClose} autofocus>
				{confirmLabel}
			</Button>
		</div>
	</svelte:fragment>
</Modal>

<style>
	.title {
		margin-top: 30px;
		font-size: 18px;
		font-weight: 600;
	}

	.subtitle {
		margin-top: 10px;
	}
</style>
